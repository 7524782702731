export default function StepTile({ stepNum, stepName, children }) {
    return (
        <div className="bg-base-200  text-lg flex justify-around px-4 py-9 rounded-lg mt-4">
            <div className="w-1/2 leading-[47px] ps-10">
                {stepNum && <span className="font-bold">Step {stepNum}:</span>} {stepName}
            </div>
            <div className="w-1/2 text-center">
                {children}
            </div>
        </div>
    );
}
