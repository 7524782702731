// import logo from './logo.svg';
import './App.css';
import ReCAPTCHA from "react-google-recaptcha";
import StepTile from "./components/StepTile";

function App() {
  const backgroundStyle = {
    backgroundImage: 'url("https://cdn.discordapp.com/splashes/222774226917130240/c5ba92aa6742e716e46aaf84e0264490.webp?size=2048")',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };


  const handleRecaptchaChange = (value) => {
    // Hier wordt de callback-functie uitgevoerd wanneer de reCAPTCHA-waarde verandert
    console.log("reCAPTCHA-waarde:", value);
  };

  return (
    <div className="App h-screen overflow-auto" style={backgroundStyle}>
      <div className="backgroundGradient">
        <div className="container mx-auto max-w-5xl mt-8">
          <div className="text-2xl">Verify to join <span className="font-bold">Mauricejuuhh</span> Discord</div>
          <div className="flex flex-row gap-4 mt-8">
            <div className="w-2/3">


              {/* Step 1 */}
              <StepTile stepNum={1} stepName="Login with Discord">
                <a href='http://localhost/login.php?guild_id=12324567898&form_token=fvdvvdfvfdfvdvfdvdf'>
                  <button class="btn btn-primary">Login with Discord</button>
                </a>
              </StepTile>


              {/* Step 2 */}
              <StepTile stepNum={2} stepName="Solve the captcha">
                <ReCAPTCHA sitekey="6LfzvzonAAAAABtP9GOGAuUih2GYtA60I716aZr5" onChange={handleRecaptchaChange} />
              </StepTile>


              {/* Step 3 */}
              <StepTile stepName="Checking connection">
                <svg class="animate-spin h-10 w-10 text-white mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </StepTile>


              {/* Step 4 */}
              <StepTile stepName="Rejoining server">
                <svg class="animate-spin h-10 w-10 text-white mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </StepTile>


              {/* Step 5 */}
              <StepTile stepName="Verrified">
                <svg class="animate-spin h-10 w-10 text-white mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </StepTile>

            </div>

            {/* Sidebar */}
            <div className="w-1/3 bg-base-200 rounded-lg p-4">
              <div className="server">
                server
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
